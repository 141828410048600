import { Avatar, Flex, Spacer, Tooltip } from '@chakra-ui/react';

type AvatarListProps = {
  users: { id: string; name: string }[];
  iconThreshold: number;
};

const AvatarList = ({ users, iconThreshold }: AvatarListProps) => {
  return (
    <Flex alignItems='center'>
      {users.slice(0, iconThreshold).map((user) => (
        <Tooltip key={user.id} label={user.name}>
          <Avatar size='2xs' name={user.name} mr='-1' />
        </Tooltip>
      ))}
      <Spacer width='2' />
    </Flex>
  );
};

export default AvatarList;
